.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary-2);
}

// override for inner side navigation (Settings Page)
.nav-pills {
  .nav-link {
    color: var(--conjura-black);

    &.active {
      background-color: var(--conjura-white-2);
      color: var(--primary-2);
      font-weight: 700;
    }

    &:hover:not(.active) {
      background-color: var(--conjura-white-2);
      color: var(--primary-2);
    }
  }
}

.spinner-border-xs {
  height: 10px;
  width: 10px;
  border-width: 2px;
}
