.modal,
.modal-backdrop {
  z-index: 2000;
}

.modal-dialog {
  .modal-content {
    padding: 1rem 2rem;
    border: 1px solid var(--conjura-white-border);
    border-radius: var(--conjura-border-radius);
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      width: 120%;
      height: 130%;
      left: -10%;
      top: -15%;
    }
  }

  .modal-header {
    border: none;
    flex-direction: column;

    .modal-title {
      font-weight: bold;
      font-size: 1.5rem;
    }

    .modal-subtitle {
      font-size: 1.3rem;
      font-weight: bold;
    }

    .btn-close {
      position: absolute;
      top: 2rem;
      right: 2rem;
    }
  }

  .modal-footer {
    position: relative;
    border: none;
    justify-content: center;
    padding: 0.25rem 0.75rem 1rem;
  }

  &.hide-modal-btn-close .btn-close {
    display: none;
  }
}

.modal-btn-close {
  position: absolute;
  z-index: 2;
  right: 16px;
  top: 16px;
  background: none;
  border: none;
}
