.table-main {
  margin-bottom: 0;

  thead {
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }

  tbody {
    border: none;
  }

  th,
  td {
    padding: 0.8rem 0.4rem;
    font-size: 0.9rem;
    border: none;
    vertical-align: middle;
  }

  th {
    color: var(--primary-1);
  }

  tr {
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
    text-align: center;

    &:last-child {
      border-bottom-width: 0;
    }
  }

  img {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    border: none;
  }

  .highlight {
    color: var(--primary-3);
    font-weight: bold;
    text-transform: capitalize;
  }

  .active {
    background-color: rgb(var(--secondary-2-rgb) / 15%);
  }

  .disabled {
    background-color: #eee;
    color: #777;
  }
}
