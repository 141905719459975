@keyframes skeleton-loading {
  0% {
    background-color: rgb(var(--primary-1-rgb) / 5%);
  }

  100% {
    background-color: rgb(var(--primary-1-rgb) / 10%);
  }
}

.skeleton {
  position: relative;
  animation: skeleton-loading 500ms linear infinite alternate;
  background-color: rgb(var(--primary-1-rgb) / 5%);
}

.skeleton-body {
  background-color: var(--conjura-white);
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;

  &.bordered {
    border: 1px solid var(--conjura-white-border);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
}

.skeleton-content {
  flex: 1;
  width: 100%;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
}

.skeleton-footer {
  width: 30%;
  height: 0.7rem;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
}

.skeleton-badge {
  width: 100%;
  height: 1.5rem;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
}
