.color-primary-2 {
  color: var(--primary-2);
}

.color-primary-1 {
  color: var(--primary-1);
}

.color-primary-3 {
  color: var(--primary-3);
}

.color-secondary-2 {
  color: var(--secondary-2);
}

.color-black {
  color: var(--conjura-black);
}

.color-white {
  color: var(--conjura-white);
}

.color-gray {
  color: var(--conjura-gray);
}

.color-text {
  color: var(--text-1);
}

.color-text-2 {
  color: var(--text-2);
}

.bg-primary-4 {
  background-color: var(--primary-4);
}

.bg-primary-3 {
  background-color: var(--primary-3);
}

.bg-primary-2 {
  background-color: var(--primary-2);
}

.bg-primary-1 {
  background-color: var(--primary-1);
}

.bg-secondary-4 {
  background-color: var(--secondary-4);
}

.bg-secondary-3 {
  background-color: var(--secondary-3);
}

.bg-secondary-2 {
  background-color: var(--secondary-2);
}

.bg-secondary-1 {
  background-color: var(--secondary-1);
}

.bg-conjura-white {
  background-color: var(--conjura-white);
}

.bg-conjura-white-2 {
  background-color: var(--conjura-white-2);
}

// bs -> bootstrap
.bg-gradient-light-bs {
  --opacity-start: 0.1;
  --opacity-end: 1;

  background: linear-gradient(
    rgb(var(--bs-light-rgb) / var(--opacity-start)),
    rgb(var(--bs-light-rgb) / var(--opacity-end))
  );
}

.border-light {
  // Override bootstrap border-light class
  border-color: var(--conjura-white-border) !important;
}

.border-primary-2 {
  border-color: var(--primary-2) !important;
}

.border-primary-3 {
  border-color: var(--primary-3);
}

.border-secondary-2 {
  border-color: var(--secondary-2);
}

.border-secondary-1 {
  border-color: var(--secondary-1);
}

.border-bottom-primary-3 {
  border: none;
  border-bottom: 2px solid var(--primary-3);
}

.border-bottom-secondary-2 {
  border: none;
  border-bottom: 2px solid var(--secondary-2);
}

.border-primary-3-to-secondary-1 {
  position: relative;
  isolation: isolate; // Creates a new stacking context

  &::before {
    content: "";
    position: absolute;
    inset: -2px; // Changed from 0 to -2px to create exact 2px border
    border-radius: 12px; // Increased to account for the 2px border
    background-image: linear-gradient(90deg, var(--primary-3), var(--secondary-1));
    z-index: -2;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    background-color: var(--primary-2);
    z-index: -1; // Above the gradient but below the content
  }
}
