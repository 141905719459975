@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"); // body text
@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap"); // monospace

@font-face {
  font-family: TTFirs;
  src: url("/common/assets/fonts/TTFirs-Extrabold.woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: TTFirs;
  src: url("/common/assets/fonts/TTFirs-Bold.woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: TTFirs;
  src: url("/common/assets/fonts/TTFirs-Medium.woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: TTFirsNeue;
  src: url("/common/assets/fonts/TTFirsNeueMedium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("/common/assets/fonts/Inter.ttf");
  font-weight: 500;
  font-display: swap;
}

code {
  font-family: "Space Mono", monospace;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
th {
  font-family: TTFirs, sans-serif;
  font-weight: 700;
}

td {
  font-family: TTFirs, sans-serif;
  font-weight: 500;
}

.font-family-ttfirs-nue {
  font-family: TTFirsNeue, sans-serif;
}

.font-family-ttfirs {
  font-family: TTFirs, sans-serif;
}

// Bootstrap has fs-1 to fs-6 classes for font sizes, adding more fs-* to support smaller font sizes than 1rem
.fs-7 {
  font-size: var(--fs-7) !important;
}

.fs-8 {
  font-size: var(--fs-8) !important;
}

.fs-9 {
  font-size: var(--fs-9) !important;
}
