// Toast customization

.Toastify__toast-container {
  .Toastify__toast {
    padding: 10px;
    border-radius: 8px;
    font-family: TTFirs, sans-serif;
    background-image: url("/common/assets/img/backgrounds/bg-conjura-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;

    .Toastify__toast-body {
      padding: 0;
    }
  }
}

.alert-danger {
  padding: 1rem 1.5rem 1rem 2.25rem;
  border: solid 1px var(--secondary-2);
  border-radius: 0;
  background-color: rgb(var(--secondary-2-rgb) / 15%);
  color: var(--conjura-black);
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='40px' height='36px' viewBox='0 0 40 36' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicons/warning%3C/title%3E%3Cg id='icons/warning' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cellipse id='Oval' fill='%23FF911D' cx='21.0079376' cy='27.35' rx='1.35793763' ry='1.35'%3E%3C/ellipse%3E%3Cpath d='M19.2983207,2.69639868 L1.6326593,32.1823916 C1.12174423,33.0351668 1.39887662,34.1406562 2.25165183,34.6515712 C2.53116895,34.8190356 2.85090165,34.9074851 3.1767454,34.9074851 L36.9643331,34.9074851 C37.9584457,34.9074851 38.7643331,34.1015977 38.7643331,33.1074851 C38.7643331,32.8056955 38.688454,32.5087497 38.5436739,32.2439559 L22.4217476,2.75796305 C21.9448336,1.88571722 20.8511235,1.56523745 19.9788777,2.04215146 C19.6978589,2.19580284 19.4629265,2.42165294 19.2983207,2.69639868 Z' id='Path-2' stroke='%23FF911D' stroke-width='1.8'%3E%3C/path%3E%3Cline x1='21' y1='13' x2='21' y2='24' id='Path-3' stroke='%23FF911D' stroke-width='2'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  margin-bottom: 0.1rem;
}
