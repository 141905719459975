@import "variables"; /* stylelint-disable-line import-notation */
@import "bootstrap"; /* stylelint-disable-line import-notation */
@import "bootstrap_overrides"; /* stylelint-disable-line import-notation */
@import "typography"; /* stylelint-disable-line import-notation */
@import "forms"; /* stylelint-disable-line import-notation */
@import "tables"; /* stylelint-disable-line import-notation */
@import "notifications"; /* stylelint-disable-line import-notation */
@import "modals"; /* stylelint-disable-line import-notation */
@import "icons"; /* stylelint-disable-line import-notation */
@import "colors"; /* stylelint-disable-line import-notation */
@import "buttons"; /* stylelint-disable-line import-notation */
@import "cards"; /* stylelint-disable-line import-notation */
@import "skeleton"; /* stylelint-disable-line import-notation */
@import "utils"; /* stylelint-disable-line import-notation */

body {
  margin: 0;
  color: var(--conjura-black);
  background-color: var(--conjura-white-2);
  font-family: TTFirs, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root {
  height: 100%;
}

a {
  color: var(--primary-3);

  &:hover {
    color: var(--secondary-2);
  }
}

hr.line-break {
  background-color: #dee2e6;
  opacity: 1;
  margin: 0.5rem 0;
}
