@use "sass:map";

@import "~bootstrap/scss/functions"; /* stylelint-disable-line import-notation, no-invalid-position-at-import-rule */
@import "~bootstrap/scss/variables"; /* stylelint-disable-line import-notation, no-invalid-position-at-import-rule */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  xxxxl: 1900px,
  xxxxxl: 2100px,
);

$breakpoint-xs: map.get($grid-breakpoints, xs); // <576px
$breakpoint-sm: map.get($grid-breakpoints, sm); // >=576px
$breakpoint-md: map.get($grid-breakpoints, md); // >= 768px
$breakpoint-lg: map.get($grid-breakpoints, lg); // >= 992px
$breakpoint-xl: map.get($grid-breakpoints, xl); // >= 1200px
$breakpoint-xxl: map.get($grid-breakpoints, xxl); // >= 1400px
$breakpoint-xxxl: map.get($grid-breakpoints, xxxl); // >= 1600px
$breakpoint-xxxxl: map.get($grid-breakpoints, xxxxl); // >= 1900px
$breakpoint-xxxxxl: map.get($grid-breakpoints, xxxxxl); // >= 2100px

:root {
  --toastify-toast-min-height: 96px;
  --bs-success-rgb: 26, 135, 65;
  --fs-7: 0.9375rem;
  --fs-8: 0.8125rem;
  --fs-9: 0.685rem;
  --conjura-white: #fff;
  --conjura-white-2: #f5f7f9;
  --conjura-white-border: #eaebef;
  --conjura-white-border-2: #d9d9d9;
  --conjura-black: #000;
  --conjura-gray: #808080;
  --conjura-orange: #ff8f1c;
  --conjura-danger: #dc3545;
  --conjura-border-radius: 10px;
  --conjura-button-radius: 7px;

  /*
  Following css variables are dynamically injected by the `src/common/hooks/usePartnerTheme`. Values might differ based on the theme selected.
  --primary-1: #200a49;
  --primary-1-rgb: 32, 10, 73;
  --primary-2: #411492;
  --primary-2-rgb: 65, 20, 146;
  --primary-3: #7035eb;
  --primary-3-rgb: 112, 53, 235;
  --primary-4: #8c75f1;
  --primary-4-rgb: 140, 117, 241;
  --primary-5: #d2afff;
  --primary-5-rgb: 210, 175, 255;
  --secondary-1: #d07514;
  --secondary-1-rgb: 208, 117, 20;
  --secondary-2: #ff911d;
  --secondary-2-rgb: 255, 145, 29;
  --secondary-3: #fff6da;
  --secondary-3-rgb: 255, 246, 218;
  --secondary-4: #ffc990;
  --secondary-4-rgb: 255, 201, 144;
  --text-1: #5e5e5e;
  --text-1-rgb: 94, 94, 94;
  --text-2: #737373;
  --text-2-rgb: 115, 115, 115;
  --text-3: #8c8c8c;
  --text-3-rgb: 140, 140, 140;
  --error-code-link: #ff911d;
  --error-code-link-rgb: 255, 145, 29;
  --from-primary-1: #411492;
  --from-primary-1-rgb: 65, 20, 146;
  --to-primary-1: #1f112f;
  --to-primary-1-rgb: 31, 17, 47;
  */
}
