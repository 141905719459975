.btn-primary {
  background-color: var(--primary-2);
  border: none var(--primary-2);
  color: var(--conjura-white);

  &:hover,
  &:focus,
  &:active {
    color: var(--conjura-white);
    background-color: var(--primary-1);
  }

  &.disabled,
  &:disabled {
    color: var(--conjura-white);
    background-color: var(--primary-2);
    border-color: var(--primary-2);
  }
}

.btn-outline-primary {
  background-color: var(--conjura-white);
  color: var(--primary-2);
  border-color: var(--primary-2);
  border-width: 1.5px;

  &:hover,
  &:focus,
  &:active {
    color: var(--primary-2);
    background-color: var(--conjura-white);
    border-color: var(--primary-2);
  }
}

.btn-secondary {
  background-color: var(--secondary-2);
  border: none var(--secondary-2);
  color: var(--conjura-white);
  font-weight: 600;

  &:hover,
  &:focus,
  &:active {
    color: var(--conjura-white);
    background-color: var(--secondary-1);
  }

  &.disabled,
  &:disabled {
    color: var(--conjura-white);
    background-color: var(--secondary-2);
    border-color: var(--secondary-2);
  }
}

.btn-light {
  color: var(--conjura-black);
  border: 1px solid var(--conjura-white-border);
  background-color: var(--conjura-white);

  &:hover,
  &:focus,
  &:active {
    border: 1px solid var(--conjura-white-border-2);
  }
}

.btn-outline-danger {
  color: var(--conjura-danger);
  background-color: var(--conjura-white);

  &:hover,
  &:focus,
  &:active {
    color: var(--conjura-danger);
    background-color: var(--conjura-white);
  }
}

label,
input,
textarea,
select {
  font-family: TTFirs, sans-serif;
}

input,
.form-control {
  font-weight: 500;
}

.form-control {
  border-radius: var(--conjura-button-radius);
  padding: 0.75rem 1rem;
  background-color: var(--conjura-white-2);
  border: 1px solid var(--conjura-white-border);
  color: var(--conjura-black);

  &::placeholder {
    color: #707070;
  }

  &.is-invalid {
    border-color: var(--secondary-2);
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='40px' height='36px' viewBox='0 0 40 36' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicons/warning%3C/title%3E%3Cg id='icons/warning' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cellipse id='Oval' fill='%23FF911D' cx='21.0079376' cy='27.35' rx='1.35793763' ry='1.35'%3E%3C/ellipse%3E%3Cpath d='M19.2983207,2.69639868 L1.6326593,32.1823916 C1.12174423,33.0351668 1.39887662,34.1406562 2.25165183,34.6515712 C2.53116895,34.8190356 2.85090165,34.9074851 3.1767454,34.9074851 L36.9643331,34.9074851 C37.9584457,34.9074851 38.7643331,34.1015977 38.7643331,33.1074851 C38.7643331,32.8056955 38.688454,32.5087497 38.5436739,32.2439559 L22.4217476,2.75796305 C21.9448336,1.88571722 20.8511235,1.56523745 19.9788777,2.04215146 C19.6978589,2.19580284 19.4629265,2.42165294 19.2983207,2.69639868 Z' id='Path-2' stroke='%23FF911D' stroke-width='1.8'%3E%3C/path%3E%3Cline x1='21' y1='13' x2='21' y2='24' id='Path-3' stroke='%23FF911D' stroke-width='2'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");

    &:focus {
      border-color: var(--secondary-2);
      box-shadow: 0 0 0 0.25rem rgb(var(--secondary-2-rgb) / 15%);
    }
  }

  &:focus {
    box-shadow: none;
    background-color: var(--conjura-white-2);
    border: 1px solid var(--conjura-white-border-2);
  }

  &.disabled,
  &:disabled {
    background-color: rgb(0 0 0 / 3%);
    cursor: not-allowed;
  }
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
  border-radius: var(--bs-border-radius-sm);
  font-size: unset;
}

.input-group-text {
  border-radius: 0;
}

.form-label {
  font-size: 0.82rem;
  margin-bottom: 0.25rem;
  font-weight: 600;
  color: var(--conjura-black);
}

.form-text {
  color: var(--text-1);
  font-size: 0.875em;
  margin-top: 0.25rem;
}

.invalid-feedback {
  font-size: 0.85rem;
  margin-top: 0.1rem;
  font-weight: bold;
  color: var(--secondary-2);
}

.form-select {
  @extend .form-control;

  padding-right: 2rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23cecece' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-position: top 50% right 0.65rem;
  background-repeat: no-repeat;
  background-size: 1rem;
}

.error-detail {
  padding-left: 2px;
  margin-bottom: 0;

  li {
    list-style: none;
  }
}

// Dropdown button overrides

.show > .btn-primary {
  &.dropdown-toggle {
    color: var(--conjura-white);
    background-color: var(--primary-1);
  }
}

// Radio button overrides

.form-check-input {
  &:checked {
    background-color: var(--primary-2);
    border-color: var(--primary-2);
  }

  &.is-invalid:checked {
    background-color: var(--secondary-2);
    border-color: var(--secondary-2);
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(var(--primary-rgb) / 25%);
  }
}

// End of radio button overrides

// React json schema core form style overrides

.help-block {
  @extend .form-text;
}

.control-label {
  @extend .form-label;
}

.form-group {
  margin-bottom: 0.75em;

  legend {
    @extend .form-label;
  }

  legend,
  .control-label,
  .form-label {
    .required {
      color: var(--conjura-orange);
      margin-left: 0.15rem;
    }
  }
}

.radio-inline {
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
}

input[type="radio"] {
  accent-color: var(--primary-2);
  margin-right: 0.25rem;
}

select.form-control {
  @extend .form-select;
}

.form-switch {
  $checked-icon: "data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e";
  $unchecked-icon: "data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e";

  .form-check-input {
    cursor: pointer;

    &:focus {
      border-color: var(--conjura-white-border-2);
      box-shadow: none;
      background-image: url($unchecked-icon);
    }

    &:checked {
      background-color: var(--primary-2);
      background-image: url($checked-icon);
      border-color: var(--primary-1);
    }
  }

  .form-check-label {
    @extend .form-label;

    color: var(--text-1);
    cursor: pointer;
  }
}

.checkbox {
  margin-bottom: 0.25rem;

  input[type="checkbox"] {
    margin-right: 8px;
    width: 1em;
    height: 1em;
    border-radius: 0.25em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgb(0 0 0 / 25%);
    appearance: none;
    -webkit-print-color-adjust: exact;
    cursor: pointer;

    &:checked {
      background-color: var(--primary-2);
      border-color: var(--primary-2);
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
    }
  }
}
// End of rjsf overrides
