// Bootstrap override start
.btn-link {
  color: var(--primary-3);

  &:hover,
  &:focus,
  &:active {
    color: var(--secondary-2);
  }
}

.btn {
  font-size: 1em;
  font-weight: 600;
  text-decoration: none;
  padding: 0.2rem 0.6rem;
  font-family: TTFirs, sans-serif;
  vertical-align: middle;
  border-radius: var(--conjura-button-radius);

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }

  svg.icon-left {
    margin-right: 5px;
    margin-bottom: 2px;
  }

  svg.icon-right {
    margin-left: 5px;
    margin-bottom: 2px;
  }

  * {
    vertical-align: middle;
  }
}

.btn-lg {
  padding: 0.5rem 1rem;
}

.btn-sm {
  font-size: 0.85rem;
}

.btn-subtle-primary {
  border-color: #d9dbe9;
  color: var(--primary-2);
  border-width: 2px;

  &:hover {
    color: var(--conjura-white);
    border-color: var(--primary-1);
    background-color: var(--primary-1);
  }

  &:focus,
  &:active {
    color: var(--primary-2);
    border-color: var(--primary-2);
    background-color: transparent;
  }

  &.disabled,
  &:disabled {
    color: var(--primary-1);
    border-color: #d9dbe9;
  }
}

.btn-tab {
  background-color: transparent;
  border: none var(--secondary-2);
  color: var(--conjura-black);
  font-weight: 600;
  font-size: 0.9rem;
  padding: 0.3rem 1rem;

  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: rgba(0 0 0 / 5%);
  }

  &.disabled,
  &:disabled {
    color: var(--conjura-gray);
    background-color: transparent;
    border-color: transparent;
  }
}

// Bootstrap override end

.btn-google {
  background-color: var(--conjura-white);
  color: rgb(0 0 0 / 54%);
  letter-spacing: 0.22px;
  text-align: left;
  font-family: Roboto, sans-serif;
  box-shadow:
    0 0 2px 0 rgb(0 0 0 / 12%),
    0 2px 2px 0 rgb(0 0 0 / 24%);
  border-radius: 2px;

  &:hover {
    box-shadow:
      0 0 2px 0 rgb(0 0 0 / 12%),
      0 2px 2px 0 rgb(0 0 0 / 24%);
    border-radius: 2px;
    color: rgb(0 0 0 / 54%);
    background: #fff;
  }

  &:focus {
    box-shadow:
      0 0 2px 0 rgb(0 0 0 / 12%),
      0 2px 2px 0 rgb(0 0 0 / 24%);
    border-radius: 2px;
    color: rgb(0 0 0 / 54%);
    background: #fff;
  }

  &:disabled {
    background: rgb(0 0 0 / 8%);
    border-radius: 2px;
    color: rgb(0 0 0 / 40%);
    filter: grayscale(100%);
  }
}

.time-view-row {
  height: 4em;

  .timeview-button {
    height: 2em;
    border-style: solid;
    border-color: var(--conjura-white);
    border-radius: var(--conjura-border-radius);
    border-width: thin;
    background-color: var(--conjura-white);
    color: var(--conjura-black);
    font-size: 1.2em;
    margin-right: 10px;

    &:hover,
    &:focus,
    &.active {
      border-style: solid;
      border-color: var(--primary-2);
      border-radius: var(--conjura-border-radius);
      border-width: thin;
      box-shadow: 2.5px 5px 4px #888;
      color: var(--conjura-black);
      background-color: var(--conjura-white);
      margin-right: 10px;
    }

    &.disabled,
    &:disabled {
      color: var(--conjura-gray);
      background-color: var(--conjura-white);
      margin-right: 10px;
    }
  }
}

.btn-toggle-animation {
  .collapsed {
    transform: rotate(180deg);
    transition: transform 300ms ease;
  }
}

.show {
  & > .dropdown-toggle {
    &.btn-secondary {
      background-color: var(--secondary-2);
    }
  }
}
